<template>
  <div class="equipment">
    <PageTopNav
      tabsShow3
      @changeTab3="changeTab3"
      :tabList3="tabList3"
      :changeIndex3="changeIndex3"
    >
    </PageTopNav>
    <router-view></router-view>
    <MyNav></MyNav>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { getEquipmentGroupApi } from "@/request/api";

export default {
  components: {
    MyNav: () => import("@/components/MyNav.vue"),
    PageTopNav: () => import("@/components/PageTopNav.vue"),
  },
  data() {
    return {
      tabList3: [
        // {
        //   id: 1,
        //   name: "视频监控",
        // },
        // {
        //   id: 2,
        //   name: "气象采集",
        // },
        // {
        //   id: 3,
        //   name: "水质检测",
        // },
        // {
        //   id: 4,
        //   name: "土壤检测",
        // },
        // {
        //   id: 5,
        //   name: "苗情监测",
        // },
        // {
        //   id: 6,
        //   name: "加热机组",
        // },
        // {
        //   id: 7,
        //   name: "水肥一体",
        // },
        // {
        //   id: 8,
        //   name: "智能投料",
        // },
        // {
        //   id: 9,
        //   name: "大棚控制",
        // },
        // {
        //   id: 10,
        //   name: "农机监测",
        // },
        // {
        //   id: 11,
        //   name: "虫情监测",
        // },
        // {
        //   id: 12,
        //   name: "阀门控制",
        // },
      ],
      changeIndex3: 0,
    };
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
    }),
  },
  created() {
    this.getEquipmentGroupApi(this.$route.query.id, this.$route.query.type);
  },
  watch: {
    "$route.query.id": {
      handler() {
        this.changeIndex3 =
          sessionStorage.getItem("childTitle3") || this.tabList3["0"].id;
      },
    },
  },
  beforeDestroy() {
    sessionStorage.setItem("childTitle3", "");
  },
  methods: {
    ...mapMutations("user", ["SET_EQUIPMENT_Name", "SET_NAV"]),

    async getEquipmentGroupApi(id = "", type = "") {
      const { code, data } = await getEquipmentGroupApi();
      if (code === 200) {
        if (this.farmID === 59) {
          let ai = {
            99: {
              cid: 999,
              icon: "AI",
              id: 999,
              name: "AI",
            },
          };
          this.tabList3 = { ...data, ...ai };
        } else {
          this.tabList3 = data;
        }

        if (type == "quantity") {
          const dataArr = Object.values(data);
          const { id } = dataArr.find((item) => item.icon === "insect");
          this.changeIndex3 = id;
          sessionStorage.setItem("childTitle3", id);
          sessionStorage.setItem("equipmentId", id);
          sessionStorage.setItem("equipmentName", "insect");
          this.SET_EQUIPMENT_Name({ icon: "insect" });
          this.$router.push({
            name: "chemical",
            query: { type: "quantity", id },
          });
          return;
        }
        if (!sessionStorage.getItem("equipmentName")) {
          this.changeIndex3 =
            sessionStorage.getItem("childTitle3") ||
            id ||
            this.tabList3["0"].id;
          if (this.farmID === 72 || this.farmID === 88) {
            switch (id) {
              case "3160":
                this.SET_NAV({ nav: id });
                sessionStorage.setItem("navIndex", "sporeMonitoring");
                sessionStorage.setItem("childTitle", "0");
                sessionStorage.setItem("childTitle3", 3160);
                sessionStorage.setItem("equipmentId", 3160);
                sessionStorage.setItem("equipmentName", "spore");
                this.SET_EQUIPMENT_Name({ icon: "spore" });
                this.$router.push({
                  name: "chemical",
                  query: { id: 3160 },
                });
                break;
              case "810":
                this.SET_NAV({ nav: id });
                sessionStorage.setItem("navIndex", "monitorSystem");
                sessionStorage.setItem("childTitle", "0");
                sessionStorage.setItem("childTitle3", 810);
                sessionStorage.setItem("equipmentId", 810);
                sessionStorage.setItem("equipmentName", "gather");
                this.SET_EQUIPMENT_Name({ icon: "gather" });
                this.$router.push({
                  name: "gather",
                  query: { id: 810 },
                });
                break;
              case "811":
                this.SET_NAV({ nav: id });
                sessionStorage.setItem("navIndex", "meteorological");
                sessionStorage.setItem("childTitle", "0");
                sessionStorage.setItem("childTitle3", 811);
                sessionStorage.setItem("equipmentId", 811);
                sessionStorage.setItem("equipmentName", "meteorological");
                this.SET_EQUIPMENT_Name({ icon: "meteorological" });
                this.$router.push({
                  name: "meteorological",
                  query: { id: 811 },
                });
                break;
              case "812":
                this.SET_NAV({ nav: id });
                sessionStorage.setItem("navIndex", "waterQuality");
                sessionStorage.setItem("childTitle", "0");
                sessionStorage.setItem("childTitle3", 812);
                sessionStorage.setItem("equipmentId", 812);
                sessionStorage.setItem("equipmentName", "water");
                this.SET_EQUIPMENT_Name({ icon: "water" });
                this.$router.push({
                  name: "chemical",
                  query: { id: 812 },
                });
                break;
              case "813":
                this.SET_NAV({ nav: id });
                sessionStorage.setItem("navIndex", "soilTesting");
                sessionStorage.setItem("childTitle", "0");
                sessionStorage.setItem("childTitle3", 813);
                sessionStorage.setItem("equipmentId", 813);
                sessionStorage.setItem("equipmentName", "soil");
                this.SET_EQUIPMENT_Name({ icon: "soil" });
                this.$router.push({
                  name: "chemical",
                  query: { id: 813 },
                });
                break;
              default:
                sessionStorage.setItem("equipmentId", data[0].id);
                sessionStorage.setItem("equipmentName", data[0].icon);
                break;
            }
          } else {
            sessionStorage.setItem("equipmentId", data[0].id);
            sessionStorage.setItem("equipmentName", data[0].icon);
          }
        }
        this.changeIndex3 =
          sessionStorage.getItem("childTitle3") || this.tabList3["0"].id;
        if (!id) {
          this.changeTab3({ id: "", icon: "" });
        }
      }
    },
    changeTab3({ id, icon }) {
      if (id) {
        this.changeIndex3 = id;
      }
      sessionStorage.setItem("childTitle3", this.changeIndex3);
      sessionStorage.setItem("equipmentId", id);
      sessionStorage.setItem("equipmentName", icon);
      this.SET_EQUIPMENT_Name({ icon });
      switch (icon) {
        case "spore":
          this.SET_NAV({ nav: "sporeMonitoring" });
          sessionStorage.setItem("navIndex", "sporeMonitoring");
          this.$router.push({
            name: "chemical",
            query: { id },
          });
          break;
        case "weather":
          this.SET_NAV({ nav: "meteorological" });
          sessionStorage.setItem("navIndex", "meteorological");
          this.$router.push({
            name: "meteorological",
            query: { id },
          });
          break;

        case "video":
          this.SET_NAV({ nav: "monitorSystem" });
          sessionStorage.setItem("navIndex", "monitorSystem");
          this.$router.push({
            name: "gather",
            query: { id },
          });
          break;
        case "water":
          this.SET_NAV({ nav: "waterQuality" });
          sessionStorage.setItem("navIndex", "waterQuality");
          this.$router.push({
            name: "chemical",
            query: { id },
          });
          break;
        case "soil":
          this.SET_NAV({ nav: "soilTesting" });
          sessionStorage.setItem("navIndex", "soilTesting");
          this.$router.push({
            name: "chemical",
            query: { id },
          });
          break;
        case "heat":
          this.SET_NAV({ nav: "oxygen" });
          sessionStorage.setItem("navIndex", "oxygen");
          this.$router.push({
            name: "chemical",
            query: { id },
          });
          break;
        case "fertilizer":
          this.$router.push({
            name: "chemical",
            query: { id },
          });
          break;
        case "feed":
          this.SET_NAV({ nav: "feeder" });
          sessionStorage.setItem("navIndex", "feeder");
          this.$router.push({
            name: "chemical",
            query: { id },
          });
          break;
        case "control":
          this.$router.push({
            name: "chemical",
            query: { id },
          });
          break;
        case "machine":
          this.$router.push({
            name: "chemical",
            query: { id },
          });
          break;
        case "insect":
          this.SET_NAV({ nav: "insectMonitoring" });
          sessionStorage.setItem("navIndex", "insectMonitoring");
          this.$router.push({
            name: "chemical",
            query: { id },
          });
          break;
        case "gate":
          this.$router.push({
            name: "chemical",
            query: { id },
          });
          break;
        case "grow":
          this.SET_NAV({ nav: "seedlingMonitoring" });
          sessionStorage.setItem("navIndex", "seedlingMonitoring");
          this.$router.push({
            name: "chemical",
            query: { id },
          });
          break;
        case "shuifei":
          this.$router.push({
            name: "chemical",
          });
          break;
        case "AI":
          this.$router.push({
            name: "AI",
          });
          break;
        case "":
          this.$router.push({
            name: "gather",
            query: { id: this.changeIndex3 },
          });
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.equipment {
  width: 100%;
  min-height: 100vh;
  background-color: #071a2c;
  padding: 62px 0 0;
  color: #fff;
  overflow: hidden;
}
</style>
